// BOOTSTRAP PADDING/MARGIN UTILITIES
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer,
    ),
    2: (
      $spacer * 2,
    ),
    3: (
      $spacer * 3,
    ),
    4: (
      $spacer * 4,
    ),
    5: (
      $spacer * 5,
    ),
  ),
  $spacers
);

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length;
    }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length;
    }
  }
}

.m-auto {
  margin: auto;
}
.my-auto,
.mt-auto {
  margin-top: auto;
}
.my-auto,
.mb-auto {
  margin-bottom: auto;
}
.mx-auto,
.ml-auto {
  margin-left: auto;
}
.mx-auto,
.mr-auto {
  margin-right: auto;
}

.flex {
  display: flex;

  &__row-wrap {
    flex-flow: row wrap;
  }
}

.text-center {
  text-align: center;
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important; /* Hat tip Nick/cssremedy (https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/#comment-1700170) */
    transition-duration: 0.001ms !important;
  }
}

.hidden {
  display: none !important;
}
