*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body {
  margin: 0px auto;
  width: 100%;
  box-sizing: border-box;
  background: $clr-darkest-primary;
  color: $clr-white;
  // scroll-behavior: smooth;
}

body {
  position: relative;
  height: 100%;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
