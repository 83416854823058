.header {
  &__index {
    height: 100vh;
    position: relative;
    background-color: $clr-black;
    color: $clr-white;
    background-image: url(../../../public/img/header-splash.jpg);
    background-size: cover;
    background-position: top center;
  }

  &__wrapper {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__image {
    &-profile {
      height: 20rem;
      width: 20rem;
      border-radius: 100%;
      display: block;
      margin: auto;
    }
  }
}

.page-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: $ff-sans;

  h1 {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 4rem;
    margin: 3rem auto 1rem;
    color: $clr-primary;
  }

  .subhead {
    letter-spacing: 3px;
    font-weight: 300;
    margin-bottom: 1.5rem;
  }
}

.fa-jedi-order,
.fa-galactic-republic {
  font-size: 5rem;
  color: $clr-primary;
}

a.scroll-down {
  color: white;
  content: '';
  width: 90px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url(../../../public/img/down-arrow.png);
  position: absolute;
  display: block;
  bottom: 3rem;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.skipLink {
  position: absolute;
  left: -1000px;
  color: white;
  background-color: black;
}

.skipLink:focus,
.skipLink:active {
  left: 0;
  top: 0;
  z-index: 200;
}
