#about,
#portfolio,
#services,
#contact {
  padding: 10rem 1rem 10rem;

  @media #{$mq-medium} {
    padding: 5rem 5rem 8rem;
  }
}

#about {
  img.about {
    height: auto;
    max-width: 100%;
    display: block;
    margin: auto;
    border: 1px solid $clr-secondary;
    border-radius: 4px;
    padding: 1rem;
  }

  article {
    ul {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        flex: 1 0 50%;
        margin-bottom: 3rem;

        a {
          display: inline-block;
          font-family: $ff-mono;

          svg {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  @media #{$mq-medium} {
    article {
      ul {
        li {
          flex: 0 0 auto;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.btn__container {
  justify-content: space-evenly;
  margin-block: 2rem;
}

#portfolio {
  .flex {
    gap: 2rem;
    justify-content: center;
  }
}

.project {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  position: relative;
  flex: 1 1 100%;
  font-family: $ff-sans;

  &__body {
    background: $clr-darker-primary;
    padding: 2rem;

    &:hover {
      .project__content {
        display: flex;
        visibility: visible;
        flex-direction: column;
        justify-content: center;
        opacity: 1;
      }
    }
  }

  &__image {
    img {
      display: block;
      max-width: 75%;
      height: auto;
      margin: 3rem auto;
    }
  }

  &__header {
    text-align: center;
  }

  &__content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 2rem;
    left: 2rem;
    bottom: 2rem;
    right: 2rem;
    padding: 2rem;
    background: darken($clr-darker-primary, 5%);
    transition: all 0.45s ease-in-out;

    a {
      display: inline-block;
      margin-bottom: 1rem;
    }
  }

  &__tools {
    list-style-type: none;

    li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 1.5rem;
        font-family: $ff-mono;
        letter-spacing: 1.5px;
      }
    }
  }

  @media #{$mq-small} {
    flex: 0 0 48%;
  }

  @media #{$mq-medium} {
    flex: 0 0 30%;
  }
}

#contact {
  padding: 10rem auto;

  .btn {
    margin: 10rem auto;
  }
}
